import React from 'react';

import { IconProps } from './icons.types';

export const WorldIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M26.224 15.1954C26.224 12.9704 25.5902 10.5999 24.354 8.74984C23.1179 6.89979 21.3609 5.45785 19.3052 4.60636C17.2495 3.75487 14.9875 3.53209 12.8052 3.96617C10.623 4.40025 8.61839 5.47171 7.04505 7.04505C5.47171 8.61839 4.40025 10.623 3.96617 12.8052C3.53209 14.9875 3.75487 17.2495 4.60636 19.3052C5.45785 21.3609 6.89979 23.1179 8.74984 24.354C10.5999 25.5902 12.0393 26.3649 15.7893 26.3649"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 11.25H25.5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16669 18.3333H15"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.375 3.75C12.2692 7.12451 11.1528 11.0223 11.1528 15C11.1528 18.9777 12.2692 22.8755 14.375 26.25"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.625 3.75C17.6465 6.98717 19.1968 10.5028 19.1968 15.0861"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.75 22.5C18.75 23.4946 19.1451 24.4484 19.8483 25.1517C20.5516 25.8549 21.5054 26.25 22.5 26.25C23.4946 26.25 24.4484 25.8549 25.1517 25.1517C25.8549 24.4484 26.25 23.4946 26.25 22.5C26.25 21.5054 25.8549 20.5516 25.1517 19.8483C24.4484 19.1451 23.4946 18.75 22.5 18.75C21.5054 18.75 20.5516 19.1451 19.8483 19.8483C19.1451 20.5516 18.75 21.5054 18.75 22.5Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.25 25.25L27.5 27.5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

WorldIcon.displayName = 'WorldIcon';
