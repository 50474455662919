import React from 'react';

import { IconProps } from './icons.types';

export const CopyLiveIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M8.61115 12.5279L9.89059 11.2334C8.90383 11.1499 8.19303 10.8242 7.68293 10.3148C6.26969 8.90344 6.26969 6.9075 7.67456 5.51285L10.4509 2.73189C11.8641 1.32889 13.8544 1.32054 15.2676 2.73189C16.6892 4.1516 16.6725 6.13919 15.276 7.54219L13.8544 8.95355C14.122 9.57154 14.2139 10.3231 14.0634 10.9745L16.4551 8.59445C18.5122 6.5484 18.5206 3.62547 16.4467 1.55437C14.3645 -0.525081 11.4544 -0.508379 9.39721 1.54602L6.48711 4.45224C4.42997 6.50664 4.4216 9.42122 6.49547 11.4923C6.98049 11.985 7.64948 12.3525 8.61115 12.5279ZM9.38885 5.47109L8.10941 6.76553C9.09617 6.85739 9.80697 7.17474 10.3171 7.68416C11.7387 9.09552 11.7303 11.0915 10.3254 12.4945L7.54913 15.2671C6.13589 16.6784 4.14564 16.6784 2.74077 15.2671C1.31916 13.8474 1.32753 11.8681 2.7324 10.4651L4.14564 9.04541C3.87805 8.43577 3.79443 7.68416 3.93659 7.02442L1.54495 9.41286C-0.512195 11.4589 -0.520557 14.3735 1.55331 16.4446C3.63554 18.524 6.54564 18.5073 8.60279 16.4613L11.5129 13.5467C13.57 11.4923 13.5784 8.57774 11.5045 6.50664C11.0195 6.02227 10.3589 5.65482 9.38885 5.47109Z"
          fill={color}
        />
      </svg>
    );
  },
);

CopyLiveIcon.displayName = 'CopyLiveIcon';
