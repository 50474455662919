import React from 'react';

import { IconProps } from './icons.types';

export const CopyLinkIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M6.00015 12.0001L12.0002 6.00006M8.00015 3.00006L8.46315 2.46406C9.40096 1.52639 10.6728 0.999662 11.999 0.999756C13.3252 0.99985 14.597 1.52676 15.5347 2.46456C16.4723 3.40237 16.9991 4.67425 16.999 6.00042C16.9989 7.32658 16.472 8.59839 15.5342 9.53606L15.0002 10.0001M10.0001 15.0001L9.60314 15.5341C8.65439 16.4722 7.37393 16.9984 6.03964 16.9984C4.70535 16.9984 3.42489 16.4722 2.47614 15.5341C2.0085 15.0717 1.63724 14.5211 1.38385 13.9142C1.13047 13.3073 1 12.6562 1 11.9986C1 11.3409 1.13047 10.6898 1.38385 10.0829C1.63724 9.47604 2.0085 8.92546 2.47614 8.46306L3.00014 8.00006"
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

CopyLinkIcon.displayName = 'InstagramIcon';
