import React from 'react';

import { IconProps } from './icons.types';

export const ChevronLeftIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M10.2733 4.94L7.21998 8L10.2733 11.06L9.33332 12L5.33332 8L9.33332 4L10.2733 4.94Z"
          fill={color}
        />
      </svg>
    );
  },
);

ChevronLeftIcon.displayName = 'ChevronLeftIcon';
