import React from 'react';

import { IconProps } from './icons.types';

export const WhatsappIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M7 7.99997C7 8.13258 7.05268 8.25976 7.14645 8.35352C7.24021 8.44729 7.36739 8.49997 7.5 8.49997C7.63261 8.49997 7.75979 8.44729 7.85355 8.35352C7.94732 8.25976 8 8.13258 8 7.99997V6.99997C8 6.86736 7.94732 6.74018 7.85355 6.64642C7.75979 6.55265 7.63261 6.49997 7.5 6.49997C7.36739 6.49997 7.24021 6.55265 7.14645 6.64642C7.05268 6.74018 7 6.86736 7 6.99997V7.99997ZM7 7.99997C7 9.32605 7.52678 10.5978 8.46447 11.5355C9.40215 12.4732 10.6739 13 12 13M12 13H13C13.1326 13 13.2598 12.9473 13.3536 12.8535C13.4473 12.7598 13.5 12.6326 13.5 12.5C13.5 12.3674 13.4473 12.2402 13.3536 12.1464C13.2598 12.0526 13.1326 12 13 12H12C11.8674 12 11.7402 12.0526 11.6464 12.1464C11.5527 12.2402 11.5 12.3674 11.5 12.5C11.5 12.6326 11.5527 12.7598 11.6464 12.8535C11.7402 12.9473 11.8674 13 12 13ZM1 19L2.65 15.2C1.38766 13.408 0.822667 11.217 1.06104 9.03807C1.29942 6.85915 2.32479 4.84211 3.94471 3.36549C5.56463 1.88887 7.66775 1.05418 9.85938 1.01807C12.051 0.981954 14.1805 1.7469 15.8482 3.16934C17.5159 4.59179 18.6071 6.57395 18.9172 8.74384C19.2272 10.9137 18.7347 13.1222 17.5321 14.9547C16.3295 16.7873 14.4994 18.118 12.3854 18.6971C10.2713 19.2762 8.01863 19.0639 6.05 18.1L1 19Z"
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

WhatsappIcon.displayName = 'InstagramIcon';
