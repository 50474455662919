import React from 'react';

import { IconProps } from './icons.types';

export const RectangleIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="16"
        viewBox="0 0 8 16"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path d="M0 8L8 0V16L0 8Z" fill={color} />
      </svg>
    );
  },
);

RectangleIcon.displayName = 'RectangleIcon';
