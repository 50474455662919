import React from 'react';

import { IconProps } from './icons.types';

export const DropUpIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M11.7037 7.15597C11.44 7.1635 11.214 7.26144 11.0106 7.46484L5.27762 13.3259C5.11189 13.4992 5.02148 13.7026 5.02148 13.9512C5.02148 14.4559 5.41323 14.8477 5.91797 14.8477C6.15904 14.8477 6.38504 14.7497 6.55831 14.5765L11.7037 9.30301L16.8491 14.5765C17.0223 14.7497 17.2408 14.8477 17.4894 14.8477C17.9941 14.8477 18.3859 14.4559 18.3859 13.9512C18.3859 13.7101 18.2955 13.4992 18.1297 13.3259L12.3892 7.46484C12.1934 7.25391 11.9674 7.15597 11.7037 7.15597Z"
          fill={color}
        />
      </svg>
    );
  },
);

DropUpIcon.displayName = 'DropUpIcon';
