export function isRTL(locale: string) {
  if (
    locale.startsWith('ar') ||
    locale.startsWith('fa') ||
    locale.startsWith('ur') ||
    locale.startsWith('ps') ||
    locale.startsWith('he') ||
    locale.startsWith('ui')
  ) {
    return true;
  }
  return false;
}
