import React from 'react';

import { IconProps } from './icons.types';

export const FullScreenIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M17.1904 7.04736H20.9525V10.8094C20.9525 11.4674 21.4849 11.9998 22.1429 11.9998C22.8009 11.9998 23.3333 11.4674 23.3333 10.8094V5.85693C23.3333 5.19895 22.8009 4.6665 22.1429 4.6665H17.1904C16.5324 4.6665 16 5.19895 16 5.85693C16 6.51491 16.5324 7.04736 17.1904 7.04736Z"
          fill={color}
        />
        <path
          d="M5.85742 23.3333H10.8099C11.4679 23.3333 12.0003 22.8009 12.0003 22.1429C12.0003 21.4849 11.4679 20.9525 10.8099 20.9525H7.04785V17.1904C7.04785 16.5324 6.5154 16 5.85742 16C5.19944 16 4.66699 16.5324 4.66699 17.1904V22.1429C4.66699 22.8009 5.19944 23.3333 5.85742 23.3333Z"
          fill={color}
        />
        <path
          d="M5.85742 11.9998C6.5154 11.9998 7.04785 11.4674 7.04785 10.8094V7.04736H10.8099C11.4679 7.04736 12.0003 6.51491 12.0003 5.85693C12.0003 5.19895 11.4679 4.6665 10.8099 4.6665H5.85742C5.19944 4.6665 4.66699 5.19895 4.66699 5.85693V10.8094C4.66699 11.4674 5.19944 11.9998 5.85742 11.9998Z"
          fill={color}
        />
        <path
          d="M17.1904 23.3333H22.1429C22.8009 23.3333 23.3333 22.8009 23.3333 22.1429V17.1904C23.3333 16.5324 22.8009 16 22.1429 16C21.4849 16 20.9525 16.5324 20.9525 17.1904V20.9525H17.1904C16.5324 20.9525 16 21.4849 16 22.1429C16 22.8009 16.5324 23.3333 17.1904 23.3333Z"
          fill={color}
        />
      </svg>
    );
  },
);

FullScreenIcon.displayName = 'FullScreenIcon';
