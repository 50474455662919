import React from 'react';

import { IconProps } from './icons.types';

export const QuoteIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M14.9203 14.628C13.7043 14.628 12.7123 14.196 11.9443 13.332C11.1763 12.468 10.7923 11.284 10.7923 9.78001C10.7923 7.73201 11.4163 5.86001 12.6643 4.16401C13.9443 2.46801 16.0883 1.20401 19.0963 0.372009V2.67601C18.1363 2.99601 17.3203 3.34801 16.6483 3.73201C16.0083 4.08401 15.5283 4.50001 15.2083 4.98001C14.8883 5.42801 14.7283 5.97201 14.7283 6.61201C14.7283 6.99601 14.8403 7.30001 15.0643 7.52401C15.3203 7.74801 15.6243 7.95601 15.9763 8.14801C16.3283 8.34001 16.6643 8.56401 16.9843 8.82001C17.3363 9.07601 17.6243 9.41201 17.8483 9.82801C18.1043 10.244 18.2323 10.804 18.2323 11.508C18.2323 12.5 17.9123 13.268 17.2723 13.812C16.6643 14.356 15.8803 14.628 14.9203 14.628ZM5.0323 14.628C3.8163 14.628 2.8243 14.196 2.0563 13.332C1.2883 12.468 0.904297 11.284 0.904297 9.78001C0.904297 7.73201 1.5283 5.86001 2.7763 4.16401C4.0563 2.46801 6.2003 1.20401 9.2083 0.372009V2.67601C8.2483 2.99601 7.4483 3.34801 6.8083 3.73201C6.1683 4.08401 5.6883 4.50001 5.3683 4.98001C5.0483 5.42801 4.8883 5.97201 4.8883 6.61201C4.8883 6.99601 5.0003 7.30001 5.2243 7.52401C5.4803 7.74801 5.7683 7.95601 6.0883 8.14801C6.4403 8.34001 6.7923 8.56401 7.1443 8.82001C7.4963 9.07601 7.7843 9.41201 8.0083 9.82801C8.2643 10.244 8.3923 10.804 8.3923 11.508C8.3923 12.5 8.0723 13.268 7.4323 13.812C6.7923 14.356 5.9923 14.628 5.0323 14.628Z"
          fill={color}
        />
      </svg>
    );
  },
);

QuoteIcon.displayName = 'QuoteIcon';
