import { CropDetails } from '@/lib/model';

export const IMAGE_RATIO: { ratio: number; type: CropDetails['type'] }[] = [
  { ratio: 16 / 9, type: '16x9' },
  { ratio: 2 / 1, type: '2x1' },
  { ratio: 3 / 2, type: '3x2' },
  { ratio: 4 / 3, type: '4x3' },
  { ratio: 1, type: '1x1' },
  { ratio: 9 / 16, type: '9x16' },
  { ratio: 3 / 4, type: '3x4' },
  { ratio: 2 / 3, type: '2x3' },
];
