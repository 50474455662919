import React from 'react';

import { IconProps } from './icons.types';

export const ChevronBottomIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M10.2479 14.0367C10.4676 14.0304 10.656 13.9488 10.8255 13.7793L15.603 8.89509C15.7411 8.7507 15.8164 8.58119 15.8164 8.37402C15.8164 7.9534 15.49 7.62695 15.0693 7.62695C14.8684 7.62695 14.6801 7.70857 14.5357 7.85296L10.2479 12.2475L5.9601 7.85296C5.81571 7.70857 5.63365 7.62695 5.42648 7.62695C5.00586 7.62695 4.67941 7.9534 4.67941 8.37402C4.67941 8.57492 4.75474 8.7507 4.89286 8.89509L9.67662 13.7793C9.83984 13.9551 10.0282 14.0367 10.2479 14.0367Z"
          fill={color}
        />
      </svg>
    );
  },
);

ChevronBottomIcon.displayName = 'ChevronBottomIcon';
