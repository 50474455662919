import React from 'react';
import { IconProps } from './icons.types';

export const ChevronTopIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M10.2479 5.96331C10.4676 5.96959 10.656 6.0512 10.8255 6.2207L15.603 11.1049C15.7411 11.2493 15.8164 11.4188 15.8164 11.626C15.8164 12.0466 15.49 12.373 15.0693 12.373C14.8684 12.373 14.6801 12.2914 14.5357 12.147L10.2479 7.75251L5.9601 12.147C5.81571 12.2914 5.63365 12.373 5.42648 12.373C5.00586 12.373 4.67941 12.0466 4.67941 11.626C4.67941 11.4251 4.75474 11.2493 4.89286 11.1049L9.67662 6.2207C9.83984 6.04492 10.0282 5.96331 10.2479 5.96331Z"
          fill={color}
        />
      </svg>
    );
  },
);

ChevronTopIcon.displayName = 'ChevronTopIcon';
