import React from 'react';

import { IconProps } from './icons.types';

export const MuteVolumeIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M13.5 7.00499C13.4998 6.16711 13.2657 5.34594 12.824 4.63393C12.3823 3.92193 11.7506 3.34738 11 2.97499V11.025C12.48 10.295 13.5 8.77499 13.5 7.00499ZM0 5.00499V9.00499C0 9.55499 0.45 10.005 1 10.005H4L7.29 13.295C7.92 13.925 9 13.475 9 12.585V1.41499C9 0.524992 7.92 0.0749919 7.29 0.704992L4 4.00499H1C0.45 4.00499 0 4.45499 0 5.00499Z"
          fill={color}
        />
      </svg>
    );
  },
);

MuteVolumeIcon.displayName = 'MuteVolumeIcon';
