import React from 'react';

import { IconProps } from './icons.types';

export const TelegramIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="18"
        viewBox="0 0 20 18"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M13 7L9 11L15 17L19 1L1 8L5 10L7 16L10 12"
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

TelegramIcon.displayName = 'InstagramIcon';
