'use client';

import { Channel } from '@/lib/model';
import { useGetChannelPref } from '@/providers/ChannelProvider';
import { generateChannelPath } from '@/utils';
import Link from 'next/link';
import { ComponentProps } from 'react';

type Props = ComponentProps<typeof Link>;

function getLinkWithChannelLink(channel: Channel, href: Props['href']) {
  return `/${generateChannelPath(channel)}${href}`;
}

const LinkWithChannelClientLink = ({ href, ...props }: Props) => {
  const channelPref = useGetChannelPref();
  if (!href) {
    return (
      <div style={props.style} className={props.className}>
        {props.children}
      </div>
    );
  }
  return <Link {...props} href={getLinkWithChannelLink(channelPref, href)} />;
};

export const LinkWithChannelLink = (props: Props) => {
  return <LinkWithChannelClientLink {...props} />;
};
