import React from 'react';

import { IconProps } from './icons.types';

export const DropdownIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M1 1.5L6 6.5L11 1.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

DropdownIcon.displayName = 'DropdownIcon';
