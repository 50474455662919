import React from 'react';

import { IconProps } from './icons.types';

export const UnMuteVolumeIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M3.63 3.2925C3.5373 3.38501 3.46375 3.4949 3.41357 3.61588C3.36339 3.73685 3.33756 3.86653 3.33756 3.9975C3.33756 4.12847 3.36339 4.25815 3.41357 4.37912C3.46375 4.5001 3.5373 4.60999 3.63 4.7025L7.29 8.3625L7 8.6625H4C3.45 8.6625 3 9.1125 3 9.6625V13.6625C3 14.2125 3.45 14.6625 4 14.6625H7L10.29 17.9525C10.92 18.5825 12 18.1325 12 17.2425V13.0725L16.18 17.2525C15.69 17.6225 15.16 17.9325 14.58 18.1625C14.22 18.3125 14 18.6925 14 19.0825C14 19.8025 14.73 20.2625 15.39 19.9925C16.19 19.6625 16.94 19.2225 17.61 18.6825L18.95 20.0225C19.0426 20.1151 19.1525 20.1885 19.2735 20.2386C19.3944 20.2887 19.5241 20.3145 19.655 20.3145C19.7859 20.3145 19.9156 20.2887 20.0365 20.2386C20.1575 20.1885 20.2674 20.1151 20.36 20.0225C20.4526 19.9299 20.526 19.82 20.5761 19.699C20.6262 19.5781 20.652 19.4484 20.652 19.3175C20.652 19.1866 20.6262 19.0569 20.5761 18.936C20.526 18.815 20.4526 18.7051 20.36 18.6125L5.05 3.2925C4.66 2.9025 4.03 2.9025 3.63 3.2925ZM19 11.6625C19 12.4825 18.85 13.2725 18.59 14.0025L20.12 15.5325C20.68 14.3625 21 13.0525 21 11.6625C21 7.8325 18.6 4.5525 15.22 3.2625C14.63 3.0325 14 3.4925 14 4.1225V4.3125C14 4.6925 14.25 5.0225 14.61 5.1625C17.18 6.2025 19 8.7225 19 11.6625ZM10.29 5.3725L10.12 5.5425L12 7.4225V6.0725C12 5.1825 10.92 4.7425 10.29 5.3725ZM16.5 11.6625C16.4998 10.8246 16.2657 10.0034 15.824 9.29144C15.3823 8.57944 14.7506 8.00489 14 7.6325V9.4225L16.48 11.9025C16.49 11.8225 16.5 11.7425 16.5 11.6625Z"
          fill={color}
        />
      </svg>
    );
  },
);

UnMuteVolumeIcon.displayName = 'UnMuteVolumeIcon';
