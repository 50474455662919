import React from 'react';

import { IconProps } from './icons.types';

export const PlusMiniIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="29"
        viewBox="0 0 28 29"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12.9954 21.1976C12.9954 21.7197 13.4491 22.1982 13.996 22.1858C14.5429 22.1982 15.0028 21.7135 15.0028 21.1914L15.0028 15.1631H21.0311C21.5656 15.1631 22.0317 14.7094 22.0193 14.1625C22.0317 13.6156 21.5594 13.1557 21.0249 13.1557H14.9966L15.0028 7.13353C14.9966 6.59285 14.5429 6.12674 13.996 6.13917C13.4491 6.12674 12.9954 6.59285 12.9954 7.12732V13.1557L6.96703 13.1557C6.44499 13.1557 5.96023 13.6156 5.97266 14.1625C5.96023 14.7094 6.43877 15.1631 6.96082 15.1631L12.9892 15.1631L12.9954 21.1976Z"
          fill={color}
        />
      </svg>
    );
  },
);

PlusMiniIcon.displayName = 'PlusMiniIcon';
