type TypeWithID = { id: string };

export function dedupeById<T extends TypeWithID>(array: Array<T>): Array<T> {
  if (!array || !array.length) return [];
  const seenIds = new Set();
  return array
    ?.filter(item => item?.id)
    ?.filter(item => {
      if (!seenIds.has(item?.id)) {
        seenIds.add(item.id);
        return true;
      }
      return false;
    });
}
