import React from 'react';

import { IconProps } from './icons.types';

export const CloseIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        color={color}
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M11.8203 1.35156L7.17188 6L11.8203 10.6484L10.6484 11.8203L6 7.17188L1.35156 11.8203L0.179688 10.6484L4.82812 6L0.179688 1.35156L1.35156 0.179688L6 4.82812L10.6484 0.179688L11.8203 1.35156Z"
          fill="currentColor"
        />
      </svg>
    );
  },
);

CloseIcon.displayName = 'CloseIcon';
