import React from 'react';

import { IconProps } from './icons.types';

export const HeadphoneIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M0 7.81855C0 9.95281 0.637681 12.3125 1.71014 14.1368C1.91304 14.4819 2.2971 14.5876 2.65942 14.4115C3.01449 14.9186 3.62319 15.1229 4.31159 14.9257C5.18116 14.6651 5.57246 13.9325 5.31159 13.0943L4.26812 9.74854C4.00725 8.91738 3.26812 8.53702 2.3913 8.78355C2.02174 8.8892 1.73913 9.08643 1.55797 9.34705C1.48551 8.83285 1.44203 8.31866 1.44203 7.81855C1.44203 3.96563 3.86232 1.4017 7.49275 1.4017C11.1232 1.4017 13.5507 3.96563 13.5507 7.81855C13.5507 8.31866 13.5145 8.83285 13.4348 9.34705C13.2536 9.09347 12.971 8.89625 12.5942 8.78355C11.7246 8.53702 10.9855 8.91738 10.7246 9.74854L9.68116 13.0943C9.41304 13.9396 9.81159 14.6651 10.6739 14.9257C11.3696 15.1229 11.9783 14.9186 12.3261 14.4185C12.6957 14.5876 13.0797 14.4819 13.2826 14.1368C14.3551 12.3125 15 9.95281 15 7.81855C15 3.12038 12.0072 0 7.49275 0C2.98551 0 0 3.12038 0 7.81855Z"
          fill={color}
        />
      </svg>
    );
  },
);

HeadphoneIcon.displayName = 'HeadphoneIcon';
