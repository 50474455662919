import { CropDetails } from '@/lib/model';
import { IMAGE_RATIO } from './constant';

export function getCroppedImage(
  cropDetails: Array<CropDetails>,
  width = 0,
  height = 1,
) {
  const mainCrop = cropDetails.find(detail => detail.type === 'MAIN');
  const imageRatio = IMAGE_RATIO.find(
    ({ ratio }) => Math.abs((width / height - ratio) / ratio) < 0.01,
  );

  if (!imageRatio) return mainCrop;
  const matchCrop = cropDetails.find(detail =>
    imageRatio?.type?.includes(detail.type || ''),
  );

  if (!matchCrop && mainCrop) return mainCrop;
  if (matchCrop) {
    return matchCrop;
  }
}
