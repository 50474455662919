import { Channel } from '@/lib/model';

export function generateChannelPath(channel: Channel) {
  if (!channel?.name) return '';
  return channel.name
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
}

export function extractPathsFromAllPath(path: string) {
  const [firstPath, secondPath] = path.split('-');
  const firstPathHalfName = firstPath
    ?.toLowerCase()
    ?.split(' ')[0]
    ?.slice(0, 3);
  const secondPathHalfName = secondPath
    ?.toLowerCase()
    ?.split(' ')[0]
    ?.slice(0, 3);

  return { firstPath, secondPath, firstPathHalfName, secondPathHalfName };
}

export function getAPILocale(locale?: string) {
  if (!locale) return;
  return locale.replace('-', '_');
}
