import React from 'react';

import { IconProps } from './icons.types';

export const BackIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M13 14H11C9.35963 13.9995 7.7503 14.4473 6.34597 15.295C4.94163 16.1428 3.79569 17.3582 3.03201 18.81C3.0104 18.5405 2.99972 18.2703 3.00001 18C3.00001 12.477 7.47701 8 13 8V3L23 11L13 19V14Z"
          fill={color}
        />
      </svg>
    );
  },
);

BackIcon.displayName = 'BackIcon';
