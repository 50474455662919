import React from 'react';

import { IconProps } from './icons.types';

export const ChevronRightIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M14.0367 9.75307C14.0304 9.53334 13.9488 9.34501 13.7793 9.1755L8.89509 4.39802C8.7507 4.25991 8.58119 4.18457 8.37402 4.18457C7.9534 4.18457 7.62695 4.51102 7.62695 4.93164C7.62695 5.13253 7.70857 5.32087 7.85296 5.46526L12.2475 9.75307L7.85296 14.0409C7.70857 14.1853 7.62695 14.3673 7.62695 14.5745C7.62695 14.9951 7.9534 15.3216 8.37402 15.3216C8.57492 15.3216 8.7507 15.2462 8.89509 15.1081L13.7793 10.3244C13.9551 10.1611 14.0367 9.9728 14.0367 9.75307Z"
          fill={color}
        />
      </svg>
    );
  },
);

ChevronRightIcon.displayName = 'ChevronRightIcon';
