import React from 'react';

import { IconProps } from './icons.types';

export const TwitterIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M19.1367 8.65625C19.8398 8.12891 20.4727 7.49609 21 6.72266C20.2969 7.03906 19.5938 7.25 18.8906 7.32031C19.6641 6.82812 20.2266 6.16016 20.5078 5.28125C19.7695 5.70312 18.9961 6.01953 18.1523 6.16016C17.8008 5.80859 17.3789 5.52734 16.9219 5.31641C16.4648 5.10547 15.9727 5 15.4453 5C14.7773 5 14.1797 5.17578 13.6172 5.49219C13.0547 5.84375 12.5977 6.30078 12.2812 6.86328C11.9297 7.42578 11.7891 8.05859 11.7891 8.69141C11.7891 8.97266 11.7891 9.25391 11.8594 9.53516C10.3477 9.46484 8.94141 9.11328 7.60547 8.41016C6.26953 7.74219 5.17969 6.82812 4.26562 5.66797C3.91406 6.26562 3.73828 6.89844 3.73828 7.53125C3.73828 8.16406 3.87891 8.76172 4.19531 9.28906C4.47656 9.85156 4.89844 10.2734 5.39062 10.625C4.79297 10.625 4.23047 10.4492 3.73828 10.1328V10.2031C3.73828 11.082 4.01953 11.8555 4.58203 12.5234C5.14453 13.2266 5.84766 13.6484 6.69141 13.8242C6.33984 13.8945 6.02344 13.9297 5.70703 13.9297C5.49609 13.9297 5.25 13.9297 5.03906 13.8945C5.25 14.6328 5.67188 15.2305 6.30469 15.7227C6.9375 16.2148 7.64062 16.4258 8.48438 16.4258C7.11328 17.4805 5.56641 18.0078 3.87891 18.0078C3.52734 18.0078 3.24609 18.0078 3 17.9727C4.6875 19.0977 6.58594 19.625 8.66016 19.625C10.8047 19.625 12.7031 19.0977 14.3906 17.9727C15.9023 16.9883 17.0977 15.6875 17.9414 14C18.75 12.4531 19.1719 10.8008 19.1719 9.11328C19.1719 8.90234 19.1367 8.76172 19.1367 8.65625Z"
          fill={color}
        />
      </svg>
    );
  },
);

TwitterIcon.displayName = 'TwitterIcon';
