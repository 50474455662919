import React from 'react';

import { IconProps } from './icons.types';

export const HamburgerMenuIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        color={color}
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M3 6H21V8.01562H3V6ZM3 12.9844V11.0156H21V12.9844H3ZM3 18V15.9844H21V18H3Z"
          fill="currentColor"
        />
      </svg>
    );
  },
);

HamburgerMenuIcon.displayName = 'HamburgerMenuIcon';
