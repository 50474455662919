import React from 'react';

import { IconProps } from './icons.types';

export const SearchIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M22.7538 21.4249L16.7216 15.3927C18.245 13.52 18.9917 11.1342 18.8079 8.72716C18.6241 6.32013 17.5237 4.07539 15.7337 2.45575C13.9436 0.836116 11.6003 -0.0349429 9.18697 0.0221725C6.77361 0.0792879 4.47417 1.06022 2.76273 2.76273C1.06022 4.47417 0.0792879 6.77361 0.0221725 9.18697C-0.0349429 11.6003 0.836116 13.9436 2.45575 15.7337C4.07539 17.5237 6.32013 18.6241 8.72716 18.8079C11.1342 18.9917 13.52 18.245 15.3927 16.7216L21.4249 22.7538C21.6052 22.9082 21.8371 22.9889 22.0743 22.9798C22.3115 22.9706 22.5365 22.8723 22.7044 22.7044C22.8723 22.5365 22.9706 22.3115 22.9798 22.0743C22.9889 21.8371 22.9082 21.6052 22.7538 21.4249ZM4.0917 14.7612C3.03668 13.7068 2.31805 12.3633 2.02671 10.9005C1.73536 9.43769 1.8844 7.92135 2.45496 6.54326C3.02552 5.16517 3.99197 3.98725 5.23206 3.1585C6.47216 2.32974 7.93018 1.88739 9.42172 1.88739C10.9132 1.88739 12.3713 2.32974 13.6114 3.1585C14.8515 3.98725 15.8179 5.16517 16.3885 6.54326C16.959 7.92135 17.1081 9.43769 16.8167 10.9005C16.5254 12.3633 15.8068 13.7068 14.7517 14.7612C14.0548 15.4665 13.2248 16.0264 12.3099 16.4086C11.395 16.7908 10.4133 16.9876 9.42172 16.9876C8.43017 16.9876 7.44848 16.7908 6.53354 16.4086C5.61861 16.0264 4.78863 15.4665 4.0917 14.7612Z"
          fill={color}
        />
      </svg>
    );
  },
);

SearchIcon.displayName = 'SearchIcon';
