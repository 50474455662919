import React from 'react';

import { IconProps } from './icons.types';

export const CloseMiniIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="29"
        viewBox="0 0 28 29"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M8.31361 18.4282C7.94447 18.7974 7.92689 19.4565 8.3224 19.8345C8.70033 20.23 9.3683 20.2124 9.73744 19.8433L14.0001 15.5806L18.2628 19.8433C18.6408 20.2212 19.2911 20.23 19.6691 19.8345C20.0646 19.4565 20.0558 18.7974 19.6779 18.4194L15.4152 14.1567L19.6779 9.90283C20.0558 9.51611 20.0646 8.86572 19.6691 8.48779C19.2911 8.09229 18.6408 8.10107 18.2628 8.479L14.0001 12.7417L9.73744 8.479C9.3683 8.10986 8.70033 8.09229 8.3224 8.48779C7.92689 8.86572 7.94447 9.5249 8.31361 9.89404L12.5763 14.1567L8.31361 18.4282Z"
          fill={color}
        />
      </svg>
    );
  },
);

CloseMiniIcon.displayName = 'CloseMiniIcon';
