import React from 'react';

import { IconProps } from './icons.types';

export const ShareIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M17 8.77037H15.5C15.0875 8.77037 14.75 9.1244 14.75 9.5571C14.75 9.9898 15.0875 10.3438 15.5 10.3438H17V18.9979H8V10.3438H9.5C9.9125 10.3438 10.25 9.9898 10.25 9.5571C10.25 9.1244 9.9125 8.77037 9.5 8.77037H8C7.175 8.77037 6.5 9.47842 6.5 10.3438V18.9979C6.5 19.8633 7.175 20.5713 8 20.5713H17C17.825 20.5713 18.5 19.8633 18.5 18.9979V10.3438C18.5 9.47842 17.825 8.77037 17 8.77037Z"
          fill={color}
        />
        <path
          d="M12.5 15.0642C12.9125 15.0642 13.25 14.7102 13.25 14.2775V6.41018H14.5925C14.93 6.41018 15.095 5.98534 14.855 5.74145L12.7625 3.54648C12.6125 3.38913 12.38 3.38913 12.23 3.54648L10.1375 5.74145C9.905 5.98534 10.07 6.41018 10.4075 6.41018H11.75V14.2775C11.75 14.7102 12.0875 15.0642 12.5 15.0642Z"
          fill={color}
        />
      </svg>
    );
  },
);

ShareIcon.displayName = 'ShareIcon';
