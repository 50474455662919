import React from 'react';

import { IconProps } from './icons.types';
import { Content } from '@/lib/model';
import { PlayIcon } from '@/app/components/icons/PlayIcon';
import { HeadphoneIcon } from '@/app/components/icons/HeadphoneIcon';
import { getMediaIconType } from '@/common/utils';

type Props = {
  content?: Content;
};

export const MediaIcon = React.forwardRef<SVGSVGElement, IconProps & Props>(
  ({ content, ...props }, forwardedRef) => {
    const { isVideo, isAudio } = getMediaIconType(content);
    if (isVideo) return <PlayIcon ref={forwardedRef} {...props} />;
    else if (isAudio) return <HeadphoneIcon ref={forwardedRef} {...props} />;
    return null;
  },
);

MediaIcon.displayName = 'MediaIcon';
