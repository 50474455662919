import React from 'react';

import { IconProps } from './icons.types';

export const LiveIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <circle cx="6" cy="6" r="3" fill={color} />
        <circle cx="6" cy="6" r="5.5" stroke={color} />
      </svg>
    );
  },
);

LiveIcon.displayName = 'LiveIcon';
