'use client';

import { Channel } from '@/lib/model';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

const PreferenceChannelContext = createContext({} as Channel);
const ChannelListContext = createContext(
  {} as Pick<Props, 'channels'> & {
    groupedChannels: GroupedChannels;
  },
);

/**
 * These channels have multiple language so all sub channel under them need to be
 * grouped and the language code for those are the first 3 letter of them like
 * "afr" and "bal"
 * */
const MULTI_CHANNELS = ['afrika', 'balkan'];

export type GroupedChannel = Channel & { children?: Channel[] };
export type GroupedChannels = Array<GroupedChannel>;

interface Props extends PropsWithChildren {
  channels: Channel[];
  channelPref: Channel;
  channelPath: string;
}

export const ChannelProvider = ({ children, channels, channelPref }: Props) => {
  const groupedChannels: GroupedChannels = useMemo(() => {
    const grouped = (channels as unknown as GroupedChannels).reduce(
      (acc, channel) => {
        const channelHalfName = channel.name.toLowerCase().split(' ')[0];
        if (MULTI_CHANNELS.includes(channelHalfName)) {
          if (channelHalfName in acc && acc[channelHalfName].children) {
            acc[channelHalfName].children.push(channel);
          } else {
            acc[channelHalfName] = {
              ...channel,
              children: [channel],
              id: '',
              name: channel.name.split(' ')[0],
              languageCode: channelHalfName.slice(0, 3),
            };
          }
          return acc;
        }
        acc[channelHalfName] = channel;
        return acc;
      },
      {} as Record<string, GroupedChannel>,
    );
    return Object.values(grouped);
  }, [channels]);

  return (
    <ChannelListContext.Provider
      value={{
        channels,
        groupedChannels,
      }}
    >
      <PreferenceChannelContext.Provider value={channelPref}>
        {children}
      </PreferenceChannelContext.Provider>
    </ChannelListContext.Provider>
  );
};

export const useChannelList = () => {
  const context = useContext(ChannelListContext);
  if (!context) {
    throw new Error('useChannelList must be used within a ChannelProvider');
  }
  return context;
};

export const useGetChannelPref = () => {
  const context = useContext(PreferenceChannelContext);
  if (!context) {
    throw new Error('ChannelPrefContext must be used within a ChannelProvider');
  }
  return context;
};
