import React from 'react';

import { IconProps } from './icons.types';

export const FacebookIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M9.70703 21H13.0117V12.9492H15.5781L16 9.75H13.0117V7.53516C13.0117 7.04297 13.082 6.65625 13.293 6.41016C13.5039 6.12891 13.9609 5.98828 14.5938 5.98828H16.2812V3.14062C15.6484 3.07031 14.8047 3 13.8203 3C12.5547 3 11.5703 3.38672 10.832 4.125C10.0586 4.86328 9.70703 5.88281 9.70703 7.21875V9.75H7V12.9492H9.70703V21Z"
          fill={color}
        />
      </svg>
    );
  },
);

FacebookIcon.displayName = 'FacebookIcon';
