import React from 'react';

import { IconProps } from './icons.types';

export const XIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M1 17L7.768 10.232M10.228 7.772L17 1M1 1L12.733 17H17L5.267 1H1Z"
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

XIcon.displayName = 'InstagramIcon';
