import React from 'react';

import { IconProps } from './icons.types';

export const PinIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <g clipPath="url(#clip0_2007_55)">
          <path
            d="M6.51728 16.0283L10.5818 18.3749L8.49884 21.9828C7.888 23.0408 7.80761 24.4457 8.02074 24.5687C8.22625 24.6874 9.4027 23.9153 10.0135 22.8573L12.0965 19.2495L16.1687 21.6005C16.8918 22.018 17.6198 21.8294 18.0197 21.1367C18.9689 19.4926 18.599 17.1275 17.0662 15.1769L18.9056 11.4284C20.2567 11.356 21.5679 11.159 22.2461 10.8808C22.6098 10.7254 22.867 10.5085 23.0076 10.265C23.2977 9.76261 23.1324 9.16992 22.5539 8.83594L14.3715 4.11182C13.8007 3.78223 13.1971 3.93107 12.9071 4.43343C12.7665 4.677 12.7072 5.00815 12.7545 5.40082C12.8527 6.1273 13.3454 7.36573 13.9505 8.56761L11.6239 12.0349C9.17589 11.6871 6.93506 12.5449 5.98584 14.189C5.58594 14.8816 5.79418 15.6108 6.51728 16.0283ZM14.6477 13.0905C14.1834 12.8224 13.7079 12.6088 13.2377 12.4389L15.6721 8.7497C15.7545 8.62469 15.7668 8.55062 15.6942 8.44782C14.9775 7.33376 14.5374 6.2678 14.5091 6.01803C14.4966 5.89915 14.5894 5.8614 14.6731 5.90974L20.8461 9.47371C20.9298 9.52205 20.9435 9.62131 20.8343 9.66988C20.6038 9.77024 19.4607 9.92214 18.1375 9.85847C18.0122 9.84701 17.9542 9.89471 17.8871 10.0285L15.9094 13.9813C15.5271 13.6591 15.1044 13.3542 14.6477 13.0905ZM7.61888 14.9288C7.46664 14.8409 7.44648 14.7177 7.5824 14.5526C8.55169 13.2956 11.2313 12.8028 13.9182 14.354C16.5975 15.9009 17.5105 18.4679 16.9142 19.9403C16.8315 20.1361 16.7147 20.1803 16.5624 20.0924L7.61888 14.9288Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_2007_55">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

PinIcon.displayName = 'PinIcon';
