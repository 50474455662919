import React from 'react';

import { IconProps } from './icons.types';

export const RoundedChevronRightIcon = React.forwardRef<
  SVGSVGElement,
  IconProps
>(({ color = 'currentColor', ...props }, forwardedRef) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M16 4.91699C14.4241 4.91699 12.8637 5.22738 11.4078 5.83044C9.95189 6.43349 8.62902 7.31741 7.51472 8.43171C6.40042 9.54601 5.5165 10.8689 4.91345 12.3248C4.31039 13.7807 4 15.3411 4 16.917C4 18.4929 4.31039 20.0533 4.91345 21.5092C5.5165 22.9651 6.40042 24.288 7.51472 25.4023C8.62902 26.5166 9.95189 27.4005 11.4078 28.0035C12.8637 28.6066 14.4241 28.917 16 28.917C19.1826 28.917 22.2348 27.6527 24.4853 25.4023C26.7357 23.1518 28 20.0996 28 16.917C28 13.7344 26.7357 10.6821 24.4853 8.43171C22.2348 6.18127 19.1826 4.91699 16 4.91699Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3333 16.9156L16 11.5823"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3366 16.917H10.6699"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 22.2503L21.3333 16.917"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

RoundedChevronRightIcon.displayName = 'RoundedChevronRightIcon';
